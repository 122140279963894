import React from 'react'
import Header from '../HeaderOne/Main'
import TheEvolutionofDataManagementTrendsandChallengesDetail from '../MainNewsDetails/TheEvolutionofDataManagementTrendsandChallengesDetail'
import PageHeaderTheEvolutionofDataManagementTrendsandChallenges from '../MainNewsDetails/PageHeaderTheEvolutionofDataManagementTrendsandChallenges'

function Main() {
  return (
    <>
      <Header />
      <PageHeaderTheEvolutionofDataManagementTrendsandChallenges />
      <TheEvolutionofDataManagementTrendsandChallengesDetail />
    </>
  )
}

export default Main
