import React from 'react'
import Header from '../HeaderOne/Main'
import PsychologyOfWebDesignDetail from '../MainNewsDetails/PsychologyOfWebDesignDetail'
import PageHeaderPsychologyOfWebDesign from '../MainNewsDetails/PageHeaderPsychologyOfWebDesign'

function Main() {
  return (
    <>
      <Header />
      <PageHeaderPsychologyOfWebDesign />
      <PsychologyOfWebDesignDetail />
    </>
  )
}

export default Main
