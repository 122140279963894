import React, { useEffect, useState } from 'react'
import growbg from '../../assets/images/backgrounds/training-bg.jpg'
import growshape from '../../assets/images/shapes/grow-business-shape-1.png'
import jobicon from '../../assets/images/content-icon/job-icon.png'
import employeebenefit from '../../assets/images/content-icon/employee-benefit-icon.png'
import adaptationicon from '../../assets/images/content-icon/adaptation-icon.png'
import valueicon from '../../assets/images/content-icon/value-proposition-icon.png'


function GrowBusiness() {
    const [percent, setPercent] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            if (percent < 87) {

                setPercent(percent + 5)
            }
        }, 50)
    }, [percent])
    return (
        <>
            <section className="grow-business">
                <div className="container">
                    <div className="grow-business__inner">
                        <div className="grow-business__bg" style={{ backgroundImage: {growbg} }} />
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="grow-business__left">
                                    <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">Training</span>
                                        </div>
                                        <h2 className="section-title__title">
                                            Let’s Turn your data into opportunities with our cutting-edge <span>data analysis training!</span>
                                        </h2>
                                    </div>
                                    <p className="grow-business__text">
                                        Acquire the essential skills to decode the secrets hidden in the numbers, make informed decisions and propel your career to new heights.<br />
                                        Discover the power of data and become an expert in analysis. <br />Join us today and forge a data-driven future !
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="grow-business__right">
                                    <div className="grow-business__shape-1 float-bob-x">
                                        <img src={growshape} alt="" />
                                    </div>
                                    <ul className="grow-business__right-points list-unstyled">
                                        <li>
                                            <div className="grow-business__right-points-icon">
                                                <span><img src={jobicon} alt="" /></span>
                                            </div>
                                            <h3 className="grow-business__right-points-title">
                                                Best career opportunities
                                            </h3>
                                        </li>
                                        <li>
                                            <div className="grow-business__right-points-icon">
                                                <span><img src={employeebenefit} alt="" /></span>
                                            </div>
                                            <h3 className="grow-business__right-points-title">
                                                Competitive salaries
                                            </h3>
                                        </li>
                                        <li>
                                            <div className="grow-business__right-points-icon">
                                                <span><img src={valueicon} alt="" /></span>
                                            </div>
                                            <h3 className="grow-business__right-points-title">
                                                Added value creation
                                            </h3>
                                        </li>
                                        <li>
                                            <div className="grow-business__right-points-icon">
                                                <span><img src={adaptationicon} alt="" /></span>
                                            </div>
                                            <h3 className="grow-business__right-points-title">
                                                Adaptability to change
                                            </h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GrowBusiness
