import React from 'react'
import { Link } from 'react-router-dom'
import wilfred from '../../assets/images/team/team-1-1.jpg'
import boris from '../../assets/images/team/team-1-2.jpg'
import orneyla from '../../assets/images/team/team-1-3.jpg'
import annie from '../../assets/images/team/team-1-4.jpg'

function TeamOne() {
    return (
        <>
            <section className="team-one">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="section-title__tagline-box">
                            <span className="section-title__tagline">meet our team</span>
                        </div>
                        <h2 className="section-title__title">Meet the People Behind
                        <br /> the High <span>Success</span></h2>
                    </div>
                    <div className="team-one__top">
                    </div>
                    <div className="team-one__bottom">
                        <div className="row">

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                                <div className="team-one__single">
                                    <div className="team-one__img-box">
                                        <div className="team-one__img">
                                            <img src={wilfred} alt="" />
                                        </div>
                                        <div className="team-one__hover-content">
                                            <div className="team-one__hover-arrow-box">
                                                <Link to="/team-details" className="team-one__hover-arrow"><span
                                                    className="fas fa-share-alt"></span></Link>
                                                <ul className="list-unstyled team-one__social">
                                                    <li><Link to="https://www.linkedin.com/in/wilfred-zemtsop-789344176/"><i className="fab fa-linkedin"></i></Link></li>
                                                    <li><Link to="https://www.facebook.com/zemtsop/"><i className="fab fa-facebook"></i></Link></li>
                                                    <li><Link to="tel:+237653364045"><i className="fab fa-whatsapp"></i></Link></li>
                                                    <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                                </ul>
                                            </div>
                                            <h3 className="team-one__hover-title"><Link to="/">Wilfred Zemtsop</Link>
                                            </h3>
                                            <p className="team-one__hover-sub-title">CEO & Founder</p>
                                            <p className="team-one__hover-text">Expert DATA Strategy & NEBOSH Certified
                                            </p>
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <div className="team-one__arrow-box">
                                            <Link to="/team-details" className="team-one__arrow"><span
                                                className="fas fa-share-alt"></span></Link>
                                        </div>
                                        <h3 className="team-one__title"><Link to="/">Wilfred Zemtsop</Link></h3>
                                        <p className="team-one__sub-title">CEO & Founder</p>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                <div className="team-one__single">
                                    <div className="team-one__img-box">
                                        <div className="team-one__img">
                                            <img src={orneyla} alt="" />
                                        </div>
                                        <div className="team-one__hover-content">
                                            <div className="team-one__hover-arrow-box">
                                                <Link to="/team-details" className="team-one__hover-arrow"><span
                                                    className="fas fa-share-alt"></span></Link>
                                                <ul className="list-unstyled team-one__social">
                                                    <li><Link to="https://cm.linkedin.com/in/simo-orneyla/"><i className="fab fa-linkedin"></i></Link></li>
                                                    <li><Link to="https://www.facebook.com/orneyla.simo/"><i className="fab fa-facebook"></i></Link></li>
                                                    <li><Link to="tel:694396249"><i className="fab fa-whatsapp"></i></Link></li>
                                                    <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                                </ul>
                                            </div>
                                            <h3 className="team-one__hover-title"><Link to="/team-details">Orneyla SIMO</Link>
                                            </h3>
                                            <p className="team-one__hover-sub-title">CTO</p>
                                            <p className="team-one__hover-text">Network Engineer <br /> Fullstack Java & React Developer <br /> ERP Integrator
                                            </p>
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <div className="team-one__arrow-box">
                                            <Link to="/team-details" className="team-one__arrow"><span
                                                className="fas fa-share-alt"></span></Link>
                                        </div>
                                        <h3 className="team-one__title"><Link to="/team-details">Orneyla SIMO</Link></h3>
                                        <p className="team-one__sub-title">CTO</p>
                                    </div>
                                </div>
                            </div>*/}

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                                <div className="team-one__single">
                                    <div className="team-one__img-box">
                                        <div className="team-one__img">
                                            <img src={annie} alt="" />
                                        </div>
                                        <div className="team-one__hover-content">
                                            <div className="team-one__hover-arrow-box">
                                                <Link to="/team-details" className="team-one__hover-arrow"><span
                                                    className="fas fa-share-alt"></span></Link>
                                                <ul className="list-unstyled team-one__social">
                                                    <li><Link to="https://www.linkedin.com/in/annie-rosanne-womey-bab648208/"><i className="fab fa-linkedin"></i></Link></li>
                                                    <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                                    <li><Link to="tel:+23790310615"><i className="fab fa-whatsapp"></i></Link></li>
                                                    <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                                </ul>
                                            </div>
                                            <h3 className="team-one__hover-title"><Link to="/">Annie WOMEY</Link>
                                            </h3>
                                            <p className="team-one__hover-sub-title">QHSE Consultant</p>
                                            <p className="team-one__hover-text">Quality Engineer
                                            </p>
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <div className="team-one__arrow-box">
                                            <Link to="/team-details" className="team-one__arrow"><span
                                                className="fas fa-share-alt"></span></Link>
                                        </div>
                                        <h3 className="team-one__title"><Link to="/">Annie WOMEY</Link></h3>
                                        <p className="team-one__sub-title">QHSE Consultant</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                                <div className="team-one__single">
                                    <div className="team-one__img-box">
                                        <div className="team-one__img">
                                            <img src={boris} alt="" />
                                        </div>
                                        <div className="team-one__hover-content">
                                            <div className="team-one__hover-arrow-box">
                                                <Link to="/team-details" className="team-one__hover-arrow"><span
                                                    className="fas fa-share-alt"></span></Link>
                                                <ul className="list-unstyled team-one__social">
                                                    <li><Link to="https://cm.linkedin.com/in/boris-zifack/"><i className="fab fa-linkedin"></i></Link></li>
                                                    <li><Link to="https://www.facebook.com/boris.zifack/"><i className="fab fa-facebook"></i></Link></li>
                                                    <li><Link to="tel:681110863"><i className="fab fa-whatsapp"></i></Link></li>
                                                    <li><Link to="https://www.instagram.com/boris_zif/"><i className="fab fa-instagram"></i></Link></li>
                                                </ul>
                                            </div>
                                            <h3 className="team-one__hover-title"><Link to="/">Boris ZIFACK</Link>
                                            </h3>
                                            <p className="team-one__hover-sub-title">Marketing Consultant</p>
                                            <p className="team-one__hover-text">Graphic Designer <br />Web Designer <br />Brand Marketer <br />Copywriter
                                            </p>
                                        </div>
                                    </div>
                                    <div className="team-one__content">
                                        <div className="team-one__arrow-box">
                                            <Link to="/" className="team-one__arrow"><span
                                                className="fas fa-share-alt"></span></Link>
                                        </div>
                                        <h3 className="team-one__title"><Link to="/">Boris ZIFACK</Link></h3>
                                        <p className="team-one__sub-title">Marketing Consultant</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamOne
