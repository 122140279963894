import React from 'react'
import Header from '../HeaderOne/Main'
import CareersPage from '../MainCareers/CareersPage'
import PageHeader from '../MainCareers/PageHeader'

function Main() {
  return (
    <>
      <Header/>
      <PageHeader/>
      <CareersPage/>
    </>
  )
}

export default Main
