import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainServices/PageHeader'
import Servicespage from '../MainServices/Servicespage'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <Servicespage />
    </>
  )
}

export default Main
