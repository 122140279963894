import React from 'react'
import { Link } from 'react-router-dom'

function CareersPage() {
  return (
    <>
      <section className="careers-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="careers-page__single">
                <div className="careers-page__img">
                  <img src="assets/images/resources/careers-page-img-1-1.jpg" alt="" />
                  <div className="careers-page__tag">
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="careers-page__content">
                  <ul className="careers-page__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-user-circle" />
                      </div>
                      <div className="text">
                        <p>Sinace</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-map-marker" />
                      </div>
                      <div className="text">
                        <p>New York</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="careers-page__title">
                    <Link to="/about">Senior Digital Designer</Link>
                  </h3>
                  <p className="careers-page__text">
                    Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                  </p>
                  <div className="careers-page__btn-box">
                    <Link to="/about" className="careers-page__btn thm-btn">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="careers-page__single">
                <div className="careers-page__img">
                  <img src="assets/images/resources/careers-page-img-1-2.jpg" alt="" />
                  <div className="careers-page__tag">
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="careers-page__content">
                  <ul className="careers-page__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-user-circle" />
                      </div>
                      <div className="text">
                        <p>Sinace</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-map-marker" />
                      </div>
                      <div className="text">
                        <p>New York</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="careers-page__title">
                    <Link to="/about">Lorem Ipsum is Simply</Link>
                  </h3>
                  <p className="careers-page__text">
                    Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                  </p>
                  <div className="careers-page__btn-box">
                    <Link to="/about" className="careers-page__btn thm-btn">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="careers-page__single">
                <div className="careers-page__img">
                  <img src="assets/images/resources/careers-page-img-1-3.jpg" alt="" />
                  <div className="careers-page__tag">
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="careers-page__content">
                  <ul className="careers-page__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-user-circle" />
                      </div>
                      <div className="text">
                        <p>Sinace</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-map-marker" />
                      </div>
                      <div className="text">
                        <p>New York</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="careers-page__title">
                    <Link to="/about">Printing and Typesetting</Link>
                  </h3>
                  <p className="careers-page__text">
                    Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                  </p>
                  <div className="careers-page__btn-box">
                    <Link to="/about" className="careers-page__btn thm-btn">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="careers-page__single">
                <div className="careers-page__img">
                  <img src="assets/images/resources/careers-page-img-1-4.jpg" alt="" />
                  <div className="careers-page__tag">
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="careers-page__content">
                  <ul className="careers-page__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-user-circle" />
                      </div>
                      <div className="text">
                        <p>Sinace</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-map-marker" />
                      </div>
                      <div className="text">
                        <p>New York</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="careers-page__title">
                    <Link to="/about">Lorem Ipsum has Been</Link>
                  </h3>
                  <p className="careers-page__text">
                    Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                  </p>
                  <div className="careers-page__btn-box">
                    <Link to="/about" className="careers-page__btn thm-btn">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="careers-page__single">
                <div className="careers-page__img">
                  <img src="assets/images/resources/careers-page-img-1-5.jpg" alt="" />
                  <div className="careers-page__tag">
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="careers-page__content">
                  <ul className="careers-page__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-user-circle" />
                      </div>
                      <div className="text">
                        <p>Sinace</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-map-marker" />
                      </div>
                      <div className="text">
                        <p>New York</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="careers-page__title">
                    <Link to="/about">Industry's standard Dummy</Link>
                  </h3>
                  <p className="careers-page__text">
                    Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                  </p>
                  <div className="careers-page__btn-box">
                    <Link to="/about" className="careers-page__btn thm-btn">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="careers-page__single">
                <div className="careers-page__img">
                  <img src="assets/images/resources/careers-page-img-1-6.jpg" alt="" />
                  <div className="careers-page__tag">
                    <p>Full Time</p>
                  </div>
                </div>
                <div className="careers-page__content">
                  <ul className="careers-page__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-user-circle" />
                      </div>
                      <div className="text">
                        <p>Sinace</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-map-marker" />
                      </div>
                      <div className="text">
                        <p>New York</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="careers-page__title">
                    <Link to="/about">When an unknown printer</Link>
                  </h3>
                  <p className="careers-page__text">
                    Lorem ipsum dolor sit amet, consect etur adi pisicing elit.
                  </p>
                  <div className="careers-page__btn-box">
                    <Link to="/about" className="careers-page__btn thm-btn">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default CareersPage
