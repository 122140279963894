import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import camtrack from '../../assets/images/brand/brand-1-1.png';
import dhlf from '../../assets/images/brand/brand-1-2.png';
import normashop from '../../assets/images/brand/brand-1-3.png';
import { Link } from 'react-router-dom';


function BrandOne() {

  return (

    <>
      <section className="brand-one">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="section-title__title">They <span>Trust us!</span></h2>
          </div>
          <div className="row">
              <div className="col">
                <div className="brand-one__img">
                  <Link to={"https://camtrack.net/"}><img src={camtrack} alt="" /></Link>
                </div>
              </div>

              <div className="col">
                <div className="brand-one__img">
                  <Link to={"https://diamondhilllaw.com/"}><img src={dhlf} alt="" /></Link>
                </div>
              </div>

              <div className="col">
                <div className="brand-one__img">
                  <Link to={"https://web.facebook.com/normashopCM"}><img src={normashop} alt="" /></Link>
                </div>
              </div>

              {/*}SwiperSlide className="brand-one__single">
                <div className="brand-one__img">
                  <img src="assets/images/brand/brand-1-4.png" alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide className="brand-one__single">
                <div className="brand-one__img">
                  <img src="assets/images/brand/brand-1-5.png" alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide className="brand-one__single">
                <div className="brand-one__img">
                  <img src="assets/images/brand/brand-1-5.png" alt="" />
                </div>
              </SwiperSlide>*/}
            
          </div>
        </div>
      </section>

    </>
  )
}

export default BrandOne
