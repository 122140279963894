import React from 'react'
import { Link } from 'react-router-dom'

function TeamPage() {
  return (
    <>
      <section className="team-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="team-one__single">
                <div className="team-one__img-box">
                  <div className="team-one__img">
                    <img src="assets/images/team/team-1-1.jpg" alt="" />
                  </div>
                  <div className="team-one__hover-content">
                    <div className="team-one__hover-arrow-box">
                      <Link to="/team-details" className="team-one__hover-arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                      <ul className="list-unstyled team-one__social">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-pinterest-p" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="team-one__hover-title">
                      <Link to="/team-details">Kevin Martin</Link>
                    </h3>
                    <p className="team-one__hover-sub-title">Consultant</p>
                    <p className="team-one__hover-text">
                      There are many vartion of passages of available.
                    </p>
                  </div>
                </div>
                <div className="team-one__content">
                  <div className="team-one__arrow-box">
                    <Link to="/team-details" className="team-one__arrow">
                      <span className="fas fa-share-alt" />
                    </Link>
                  </div>
                  <h3 className="team-one__title">
                    <Link to="/team-details">Kevin Martin</Link>
                  </h3>
                  <p className="team-one__sub-title">Consultant</p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="team-one__single">
                <div className="team-one__img-box">
                  <div className="team-one__img">
                    <img src="assets/images/team/team-1-2.jpg" alt="" />
                  </div>
                  <div className="team-one__hover-content">
                    <div className="team-one__hover-arrow-box">
                      <Link to="/team-details" className="team-one__hover-arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                      <ul className="list-unstyled team-one__social">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-pinterest-p" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="team-one__hover-title">
                      <Link to="/team-details">Jeesica Brown</Link>
                    </h3>
                    <p className="team-one__hover-sub-title">Consultant</p>
                    <p className="team-one__hover-text">
                      There are many vartion of passages of available.
                    </p>
                  </div>
                </div>
                <div className="team-one__content">
                  <div className="team-one__arrow-box">
                    <Link to="/team-details" className="team-one__arrow">
                      <span className="fas fa-share-alt" />
                    </Link>
                  </div>
                  <h3 className="team-one__title">
                    <Link to="/team-details">Jeesica Brown</Link>
                  </h3>
                  <p className="team-one__sub-title">Consultant</p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="team-one__single">
                <div className="team-one__img-box">
                  <div className="team-one__img">
                    <img src="assets/images/team/team-1-3.jpg" alt="" />
                  </div>
                  <div className="team-one__hover-content">
                    <div className="team-one__hover-arrow-box">
                      <Link to="/team-details" className="team-one__hover-arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                      <ul className="list-unstyled team-one__social">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-pinterest-p" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="team-one__hover-title">
                      <Link to="/team-details">Mike Hardson</Link>
                    </h3>
                    <p className="team-one__hover-sub-title">Consultant</p>
                    <p className="team-one__hover-text">
                      There are many vartion of passages of available.
                    </p>
                  </div>
                </div>
                <div className="team-one__content">
                  <div className="team-one__arrow-box">
                    <Link to="/team-details" className="team-one__arrow">
                      <span className="fas fa-share-alt" />
                    </Link>
                  </div>
                  <h3 className="team-one__title">
                    <Link to="/team-details">Mike Hardson</Link>
                  </h3>
                  <p className="team-one__sub-title">Consultant</p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="team-one__single">
                <div className="team-one__img-box">
                  <div className="team-one__img">
                    <img src="assets/images/team/team-1-4.jpg" alt="" />
                  </div>
                  <div className="team-one__hover-content">
                    <div className="team-one__hover-arrow-box">
                      <Link to="/team-details" className="team-one__hover-arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                      <ul className="list-unstyled team-one__social">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-pinterest-p" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="team-one__hover-title">
                      <Link to="/team-details">Alse Brown</Link>
                    </h3>
                    <p className="team-one__hover-sub-title">Consultant</p>
                    <p className="team-one__hover-text">
                      There are many vartion of passages of available.
                    </p>
                  </div>
                </div>
                <div className="team-one__content">
                  <div className="team-one__arrow-box">
                    <Link to="/team-details" className="team-one__arrow">
                      <span className="fas fa-share-alt" />
                    </Link>
                  </div>
                  <h3 className="team-one__title">
                    <Link to="/team-details">Alse Brown</Link>
                  </h3>
                  <p className="team-one__sub-title">Consultant</p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="team-one__single">
                <div className="team-one__img-box">
                  <div className="team-one__img">
                    <img src="assets/images/team/team-1-5.jpg" alt="" />
                  </div>
                  <div className="team-one__hover-content">
                    <div className="team-one__hover-arrow-box">
                      <Link to="/team-details" className="team-one__hover-arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                      <ul className="list-unstyled team-one__social">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-pinterest-p" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="team-one__hover-title">
                      <Link to="/team-details">Mike Hussey</Link>
                    </h3>
                    <p className="team-one__hover-sub-title">Consultant</p>
                    <p className="team-one__hover-text">
                      There are many vartion of passages of available.
                    </p>
                  </div>
                </div>
                <div className="team-one__content">
                  <div className="team-one__arrow-box">
                    <Link to="/team-details" className="team-one__arrow">
                      <span className="fas fa-share-alt" />
                    </Link>
                  </div>
                  <h3 className="team-one__title">
                    <Link to="/team-details">Mike Hussey</Link>
                  </h3>
                  <p className="team-one__sub-title">Consultant</p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="team-one__single">
                <div className="team-one__img-box">
                  <div className="team-one__img">
                    <img src="assets/images/team/team-1-6.jpg" alt="" />
                  </div>
                  <div className="team-one__hover-content">
                    <div className="team-one__hover-arrow-box">
                      <Link to="/team-details" className="team-one__hover-arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                      <ul className="list-unstyled team-one__social">
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-pinterest-p" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="team-one__hover-title">
                      <Link to="/team-details">Lora Angle</Link>
                    </h3>
                    <p className="team-one__hover-sub-title">Consultant</p>
                    <p className="team-one__hover-text">
                      There are many vartion of passages of available.
                    </p>
                  </div>
                </div>
                <div className="team-one__content">
                  <div className="team-one__arrow-box">
                    <Link to="/team-details" className="team-one__arrow">
                      <span className="fas fa-share-alt" />
                    </Link>
                  </div>
                  <h3 className="team-one__title">
                    <Link to="/team-details">Lora Angle</Link>
                  </h3>
                  <p className="team-one__sub-title">Consultant</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default TeamPage
