import React, { useState } from 'react';

function FaqPage() {
  const [activeAccordions, setActiveAccordions] = useState([]);

  const toggleAccordion = (index) => {
    if (activeAccordions.includes(index)) {
      setActiveAccordions(activeAccordions.filter((item) => item !== index));
    } else {
      setActiveAccordions([...activeAccordions, index]);
    }
  };

  const isAccordionActive = (index) => {
    return activeAccordions.includes(index);
  };

  return (
    <section className="faq-page">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="faq-page__single">
              <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-1">
                <div className={`accrodion ${isAccordionActive(0) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(0)}>
                    <h4>What does your process look like?</h4>
                  </div>
                  {isAccordionActive(0) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`accrodion ${isAccordionActive(1) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(1)}>
                    <h4>Learn how we create unmatched solutions</h4>
                  </div>
                  {isAccordionActive(1) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`accrodion ${isAccordionActive(2) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(2)}>
                    <h4>How long do services take to complete?</h4>
                  </div>
                  {isAccordionActive(2) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`accrodion ${isAccordionActive(3) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(3)}>
                    <h4>How can I find my financial record?</h4>
                  </div>
                  {isAccordionActive(3) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="faq-page__single">
              <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion-2">
                <div className={`accrodion ${isAccordionActive(4) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(4)}>
                    <h4>What does your process look like?</h4>
                  </div>
                  {isAccordionActive(4) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`accrodion ${isAccordionActive(5) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(5)}>
                    <h4>Learn how we create unmatched solutions</h4>
                  </div>
                  {isAccordionActive(5) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`accrodion ${isAccordionActive(6) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(6)}>
                    <h4>How long do services take to complete?</h4>
                  </div>
                  {isAccordionActive(6) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`accrodion ${isAccordionActive(7) ? 'active' : ''}`}>
                  <div className="accrodion-title" onClick={() => toggleAccordion(7)}>
                    <h4>How can I find my financial record?</h4>
                  </div>
                  {isAccordionActive(7) && (
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          There are many variations of passages the majority have suffered alteration in some fo
                          injected humour, or randomised words believable.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqPage;
