import React from 'react'
import { Link } from 'react-router-dom'
import footerbg from '../../assets/images/backgrounds/site-footer-bg.png'
import footerlogo from '../../assets/images/resources/footer-logo.png'
import footershape from '../../assets/images/shapes/site-footer-shape-1.png'

import footerimg1 from '../../assets/images/project/footer-widget-portfolio-img-1.jpg'
import footerimg2 from '../../assets/images/project/footer-widget-portfolio-img-2.jpg'
import footerimg3 from '../../assets/images/project/footer-widget-portfolio-img-3.jpg'
import footerimg4 from '../../assets/images/project/footer-widget-portfolio-img-4.jpg'
import footerimg5 from '../../assets/images/project/footer-widget-portfolio-img-5.jpg'
import footerimg6 from '../../assets/images/project/footer-widget-portfolio-img-6.jpg'


function Main() {
  return (
    <>
      <footer className="site-footer">
        <div className="site-footer__shape-1 float-bob-x">
          <img src={footershape} alt="" />
        </div>
        <div className="site-footer__bg" style={{ backgroundImage: "url("+(footerbg)+")" }}></div>
        <div className="site-footer__top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms" >
                <div className="footer-widget__column footer-widget__about">
                  <div className="footer-widget__logo">
                    <Link to="/">
                      <img src={footerlogo} alt="" />
                    </Link>
                  </div>
                  <p className="footer-widget__about-text">
                    Leaders in Data Analytics Technology Design & Development for small and medium compagnies.
                  </p>
                  <div className="site-footer__social">
                    <Link to="https://www.linkedin.com/company/zemtsidata ">
                      <i className="fab fa-linkedin" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-facebook" />
                    </Link>
                    <Link to="tel:+237695633783">
                      <i className="fab fa-whatsapp" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-instagram" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms" >
                <div className="footer-widget__column footer-widget__link">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Explore</h3>
                  </div>
                  <ul className="footer-widget__link-list list-unstyled">
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/">Our Team</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms" >
                <div className="footer-widget__column footer-widget__newsletter">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Newsletter</h3>
                  </div>
                  <form className="footer-widget__newsletter-form mc-form" data-url="MC_FORM_URL" noValidate="novalidate" >
                    <div className="footer-widget__newsletter-form-input-box">
                      <input type="email" placeholder="Your email" name="EMAIL" />
                      <button type="submit" className="footer-widget__newsletter-btn">
                        <span className="fas fa-paper-plane" />
                      </button>
                    </div>
                  </form>
                  <div className="mc-form__response" />
                  <ul className="footer-widget__Contact-list list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-envelope" />
                      </div>
                      <div className="text">
                        <Link to="mailto:contact@zemtsidata.com">contact@zemtsidata.com</Link>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-phone-square" />
                      </div>
                      <div className="text">
                        <Link to="tel:+237653364045">(+237)653 364 045</Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="500ms" >
                <div className="footer-widget__column footer-widget__portfolio">
                  <div className="footer-widget__title-box">
                    <h3 className="footer-widget__title">Gallery</h3>
                  </div>
                  <ul className="footer-widget__portfolio-list list-unstyled clearfix">
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img
                          src={footerimg1}
                          alt=""
                        />
                        <Link to="#">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img
                          src={footerimg2}
                          alt=""
                        />
                        <Link to="#">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img
                          src={footerimg3}
                          alt=""
                        />
                        <Link to="#">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={footerimg4} alt="" />
                        <Link to="#">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={footerimg5} alt="" />
                        <Link to="#">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="footer-widget__portfolio-img">
                        <img src={footerimg6} alt="" />
                        <Link to="#">
                          <span className="fab fa-instagram" />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="site-footer__bottom-inner">
                  <p className="site-footer__bottom-text">
                    © 2023 ZemtsiData. <Link to="#">All Rights Reserved</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Main
