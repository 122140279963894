import React from 'react'
import { Link } from 'react-router-dom'

function ServiceDetails() {
  return (
    <>
      <section className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__left">
                <div className="services-details__category">
                  <h3 className="services-details__category-title">Categories</h3>
                  <ul className="services-details__category-list list-unstyled">
                    <li>
                      <Link to="/capital-market">
                        Capital Market
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/insurance">
                        Insurance
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/mutual-funds">
                        Mutual Funds
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/portfolio-management">
                        Portfolio Mangement
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/fixed-income">
                        Fixed Income
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/loans">
                        Loans
                        <span className="icon-right-arrow" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="services-details__need-help">
                  <div className="services-details__need-help-bg" style={{ backgroundImage: "url(assets/images/backgrounds/need-help-bg.jpg)" }} />
                  <div className="services-details__need-help-shape-1 float-bob-x">
                    <img src="assets/images/shapes/need-help-shape-1.png" alt="" />
                  </div>
                  <div className="services-details__need-help-shape-2 float-bob-x">
                    <img src="assets/images/shapes/need-help-shape-2.png" alt="" />
                  </div>
                  <div className="services-details__need-help-shape-3 float-bob-y">
                    <img src="assets/images/shapes/need-help-shape-3.png" alt="" />
                  </div>
                  <div className="services-details__need-help-icon">
                    <span className="icon-consultant" />
                  </div>
                  <h3 className="services-details__need-help-title">
                    Looking
                    <br /> for a Top
                    <br /> Consulting?
                  </h3>
                  <div className="services-details__need-help-btn-box">
                    <Link to="/contact" className="services-details__need-help-btn thm-btn" >
                      Contact Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img src="assets/images/services/services-details-img-5.jpg" alt="" />
                  <div className="services-details__icon">
                    <span className="icon-insurance" />
                  </div>
                </div>
                <h3 className="services-details__title-1">Mutual Funds</h3>
                <p className="services-details__text-1">
                  Lorem ipsum is simply free text used by copytyping refreshing. Neque
                  porro est qui dolorem ipsum quia quaed inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Aelltes port lacus
                  quis enim var sed efficitur turpis gilla sed sit amet finibus eros.
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the ndustry standard dummy text ever
                  since the 1500s, when an unknown printer took a galley.
                </p>
                <div className="services-details__text-box">
                  <p className="services-details__text-box-text">
                    Lorem Ipsum is simply free text not dummy available typesetting
                    industry been the industry standard.
                  </p>
                </div>
                <p className="services-details__text-2">
                  It has survived not only five centuries. Lorem Ipsum is simply dummy
                  text of the new design printng and type setting Ipsum take a look at
                  our round. When an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only
                  five centuries, but also the leap into electronic typesetting.
                </p>
                <div className="services-details__points">
                  <div className="row">
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-writer" />
                        </div>
                        <div className="content">
                          <h3>Business Solution</h3>
                          <p>
                            There are many of lorem Ipsum the majori have suffered.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__points-single">
                        <div className="icon">
                          <span className="icon-data-analysis" />
                        </div>
                        <div className="content">
                          <h3>Market Rules</h3>
                          <p>
                            There are many of lorem Ipsum the majori have suffered.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="services-details__benefit">
                  <div className="row">
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__benefit-img">
                        <img
                          src="assets/images/services/services-details-benefit-img.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="services-details__benefit-content">
                        <h3 className="services-details__benefit-title">
                          Our Benefits
                        </h3>
                        <p className="services-details__benefit-text">
                          There are many of lorem Ipsum, but the majori have suffered.
                        </p>
                        <ul className="services-details__benefit-points list-unstyled">
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Praesent efficitur quam sit amet</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Nunc cursus dolor id purus euismod</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Quisque tincidunt eros ac place viverra</p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span className="fa fa-check" />
                            </div>
                            <div className="text">
                              <p>Cursus dolor id purus euismod</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServiceDetails
