import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainAbout/PageHeader'
import AboutFour from '../MainAbout/AboutFour'
import ExpectationOne from '../MainAbout/ExpectationOne'
import TeamOne from '../MainAbout/TeamOne'
import Testimonial from '../MainAbout/Testimonial'
import BrandOne from '../MainHomeOne/BrandOne'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <AboutFour />
      <ExpectationOne />
      <TeamOne />
      <Testimonial />
      <BrandOne />
    </>
  )
}

export default Main
