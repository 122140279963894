import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainNews/PageHeader'
import NewsPage from '../MainNews/NewsPage'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <NewsPage />

    </>
  )
}

export default Main
