import React from 'react'
import PageHeader from '../MainCapitalMarket/PageHeader'
import ServiceDetails from '../MainCapitalMarket/ServiceDetails'
import Header from '../HeaderOne/Main'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <ServiceDetails />
    </>
  )
}

export default Main
