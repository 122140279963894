import React from 'react'

function FaqSearch() {
  return (
    <>
      <section className="faq-search">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">
                frequently asked questions
              </span>
            </div>
            <h2 className="section-title__title">
              How can We <span>Help?</span>
            </h2>
          </div>
          <form className="faq-search-box__form">
            <div className="faq-search-box__form-input">
              <input type="search" placeholder="Search here anything..." />
              <button type="submit">
                <i className="icon-magnifying-glass" />
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default FaqSearch
