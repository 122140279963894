import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomeOne from '../Components/HomeOne/Main';
import Footer from '../Components/Footer/Main'
import About from '../Components/About/Main'
import Contact from '../Components/Contact/Main'
import DataStrategy from '../Components/DataStrategy/Main'
import Careers from '../Components/Careers/Main'
import FAQ from '../Components/FAQ/Main'
import DataManagement from '../Components/DataManagement/Main'
import DataIntelligence from '../Components/DataIntelligence/Main'
import DataAsAssetsMain from '../Components/DataAsAssetMain/Main'
import Blog from '../Components/Blog/Main'
import WebDevelopment from '../Components/WebDevelopment/Main'
import Services from '../Components/Services/Main'
import Team from '../Components/Team/Main'
import PsychologyOfWebDesign from '../Components/PsychologyOfWebDesign/Main'
import BuildinganEffectiveDataStrategyforBusinessSuccess from '../Components/BuildinganEffectiveDataStrategyforBusinessSuccessMain/Main'
import TheEvolutionofDataManagementTrendsandChallenges from '../Components/TheEvolutionofDataManagementTrendsandChallengesMain/Main'
import TheFundamentalRulesforRunningaSmallWebDesignBusiness from '../Components/TheFundamentalRulesforRunningaSmallWebDesignBusinessMain/Main'
import ResponsiveDesignandBrandConsistencyAcrossDevices from '../Components/ResponsiveDesignandBrandConsistencyAcrossDevicesMain/Main'

function Index() {
  return (
    <>
      <div className="page_wrapper">
        <Routes >
          <Route path="/" element={<HomeOne />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/data-strategy" element={<DataStrategy />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/data-management" element={<DataManagement />} />
          <Route path="/data-intelligence" element={<DataIntelligence />} />
          <Route path="/data-as-asset" element={<DataAsAssetsMain />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/the-psychology-of-web-design-how-user-perception-impacts-branding" element={<PsychologyOfWebDesign />} />
          <Route path="/buildingan-effective-datastrategy-for-business-success" element={<BuildinganEffectiveDataStrategyforBusinessSuccess />} />
          <Route path="/the-evolution-of-data-management-trends-and-challenges-detail" element={<TheEvolutionofDataManagementTrendsandChallenges />} />
          <Route path="/the-fundamental-rules-for-running-a-small-web-design-business" element={<TheFundamentalRulesforRunningaSmallWebDesignBusiness />} />
          <Route path="/responsive-design-and-brand-consistency-across-devices" element={<ResponsiveDesignandBrandConsistencyAcrossDevices />} />
        </Routes>
      </div>
      <Footer />
    </>
  )
}

export default Index