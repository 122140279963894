import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import project1 from '../../assets/images/project/project-1-1.jpg'
import project2 from '../../assets/images/project/project-1-2.jpg'
import project3 from '../../assets/images/project/project-1-3.jpg'

function ProjectOne() {
    let satting = {
        loop: true,
        autoplay: false,
        margin: 30,
        // nav: true,
        // dots: false,
        smartspeed: 500,
        autoplaytimeout: 10000,
        navtext: ['<span className="icon-left-arrow"></span>', '<span className="icon-right-arrow"></span>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            }

        }
    }

    return (
        <>
            <section className="project-one">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="section-title__tagline-box">
                            <span className="section-title__tagline">Recent work lists</span>
                        </div>
                        <h2 className="section-title__title">Recently Completed Our
                            <br /> Clients <span>Projects</span></h2>
                    </div>
                    <div className="project-one__bottom">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                
                                        <div className="project-one__single">
                                            <div className="project-one__img-box">
                                                <div className="project-one__img">
                                                    <img src={project1} alt="" />
                                                </div>
                                            </div>
                                            <div className="project-one__content">
                                                <p>Data analytics</p>
                                                <h3><Link to="/">NetSuite &amp; Power BI
                                                    <br /> Connection</Link></h3>
                                                <div className="project-one__arrow">
                                                    <Link to="/"><span className="icon-right-arrow"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                    
                                        <div className="project-one__single">
                                            <div className="project-one__img-box">
                                                <div className="project-one__img">
                                                    <img src={project2} alt="" />
                                                </div>
                                            </div>
                                            <div className="project-one__content">
                                                <p>Data analytics</p>
                                                <h3><Link to="/">Strategic
                                                    <br /> Dashboard</Link></h3>
                                                <div className="project-one__arrow">
                                                    <Link to="/"><span className="icon-right-arrow"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                            </div>       
                            <div className="col-xl-4 col-lg-4">
                                    
                                        <div className="project-one__single">
                                            <div className="project-one__img-box">
                                                <div className="project-one__img">
                                                    <img src={project3} alt="" />
                                                </div>
                                            </div>
                                            <div className="project-one__content">
                                                <p>Web Development</p>
                                                <h3><Link to="/">Business
                                                    <br /> Website</Link></h3>
                                                <div className="project-one__arrow">
                                                    <Link to="/"><span className="icon-right-arrow"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                            </div>

                                    {/*<SwiperSlide className="item">
                                        <div className="project-one__single">
                                            <div className="project-one__img-box">
                                                <div className="project-one__img">
                                                    <img src="assets/images/project/project-1-1.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="project-one__content">
                                                <p>Finance</p>
                                                <h3><Link to="/">International business
                                                    <br /> development</Link></h3>
                                                <div className="project-one__arrow">
                                                    <Link to="/"><span className="icon-right-arrow"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="item">
                                        <div className="project-one__single">
                                            <div className="project-one__img-box">
                                                <div className="project-one__img">
                                                    <img src="assets/images/project/project-1-2.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="project-one__content">
                                                <p>Finance</p>
                                                <h3><Link to="/">International business
                                                    <br /> development</Link></h3>
                                                <div className="project-one__arrow">
                                                    <Link to="/"><span className="icon-right-arrow"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className="item">
                                        <div className="project-one__single">
                                            <div className="project-one__img-box">
                                                <div className="project-one__img">
                                                    <img src="assets/images/project/project-1-3.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="project-one__content">
                                                <p>Finance</p>
                                                <h3><Link to="/">International business
                                                    <br /> development</Link></h3>
                                                <div className="project-one__arrow">
                                                    <Link to="/"><span className="icon-right-arrow"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>*/}
                                
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectOne
