import React from 'react'
import { Link } from 'react-router-dom'

import news1 from '../../assets/images/blog/news-1-1.jpg'
import news2 from '../../assets/images/blog/news-1-2.jpg'
import news3 from '../../assets/images/blog/news-1-3.jpg'
import news4 from '../../assets/images/blog/news-1-4.jpg'
import news5 from '../../assets/images/blog/news-1-5.jpg'
import news6 from '../../assets/images/blog/news-1-6.jpg'

function NewsPage() {
  return (
    <>
      <section className="news-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="news-one__single">
                <div className="news-one__img-box">
                  <div className="news-one__img">
                    <img src={news1} alt="" />
                    <Link to="/the-evolution-of-data-management-trends-and-challenges-detail">
                      <span className="news-one__plus" />
                    </Link>
                  </div>
                  <div className="news-one__date">
                    <p>30 March, 2023</p>
                  </div>
                </div>
                <div className="news-one__content">
                  <ul className="news-one__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-tags" />
                      </div>
                      <div className="text">
                        <p>Data Management</p>
                      </div>
                    </li>
                    <li>
                      <span>/</span>
                      <div className="icon">
                        <span className="fas fa-comments" />
                      </div>
                      <div className="text">
                        <p>2 Comments</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="news-one__title">
                    <Link to="/the-evolution-of-data-management-trends-and-challenges-detail">
                      The Evolution of Data Management: Trends and Challenges
                    </Link>
                  </h3>
                  <p className="news-one__text">
                    The evolution of data management has been a remarkable journey from manual data processing to AI-driven insights and beyond.<br />
                    <br />
                  </p>
                </div>
                <div className="news-one__hover">
                  <div className="news-one__hover-content">
                    <h3 className="news-one__hover-title">
                      <Link to="/the-evolution-of-data-management-trends-and-challenges-detail">
                        The Evolution of Data Management: Trends and Challenges
                      </Link>
                    </h3>
                    <p className="news-one__hover-text">
                      In today's data-driven world, managing and harnessing the power of data has become 
                      a critical aspect of business and technological advancement.
                    </p>
                  </div>
                  <div className="news-one__hover-btn-box">
                    <Link to="/the-evolution-of-data-management-trends-and-challenges-detail">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="news-one__single">
                <div className="news-one__img-box">
                  <div className="news-one__img">
                    <img src={news2} alt="" />
                    <Link to="/buildingan-effective-datastrategy-for-business-success">
                      <span className="news-one__plus" />
                    </Link>
                  </div>
                  <div className="news-one__date">
                    <p>1st April, 2023</p>
                  </div>
                </div>
                <div className="news-one__content">
                  <ul className="news-one__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-tags" />
                      </div>
                      <div className="text">
                        <p>Data Strategy</p>
                      </div>
                    </li>
                    <li>
                      <span>/</span>
                      <div className="icon">
                        <span className="fas fa-comments" />
                      </div>
                      <div className="text">
                        <p>2 Comments</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="news-one__title">
                    <Link to="/buildingan-effective-datastrategy-for-business-success">
                      Building an Effective Data Strategy for Business Success
                    </Link>
                  </h3>
                  <p className="news-one__text">
                    Building a robust data strategy is not only essential but often the differentiating factor between 
                    companies that thrive and those that struggle.
                  </p>
                </div>
                <div className="news-one__hover">
                  <div className="news-one__hover-content">
                    <h3 className="news-one__hover-title">
                      <Link to="/buildingan-effective-datastrategy-for-business-success">
                        Building an Effective Data Strategy for Business Success
                      </Link>
                    </h3>
                    <p className="news-one__hover-text">
                      This article will explore the key components and steps involved in crafting an effective data 
                      strategy to drive business success.
                    </p>
                  </div>
                  <div className="news-one__hover-btn-box">
                    <Link to="/buildingan-effective-datastrategy-for-business-success">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="news-one__single">
                <div className="news-one__img-box">
                  <div className="news-one__img">
                    <img src={news3} alt="" />
                    <Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">
                      <span className="news-one__plus" />
                    </Link>
                  </div>
                  <div className="news-one__date">
                    <p>5 April, 2023</p>
                  </div>
                </div>
                <div className="news-one__content">
                  <ul className="news-one__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-tags" />
                      </div>
                      <div className="text">
                        <p>Web &amp; Brand Design</p>
                      </div>
                    </li>
                    <li>
                      <span>/</span>
                      <div className="icon">
                        <span className="fas fa-comments" />
                      </div>
                      <div className="text">
                        <p>2 Comments</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="news-one__title">
                    <Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">
                      The Psychology of Web Design: How User Perception Impacts Branding
                    </Link>
                  </h3>
                  <p className="news-one__text">
                    Beyond aesthetics and functionality, the psychology of web design delves into how users perceive and 
                    interact with websites.
                  </p>
                </div>
                <div className="news-one__hover">
                  <div className="news-one__hover-content">
                    <h3 className="news-one__hover-title">
                      <Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">
                        The Psychology of Web Design: How User Perception Impacts Branding
                      </Link>
                    </h3>
                    <p className="news-one__hover-text">
                      In the digital age, web design is a powerful tool for shaping user perception and building a compelling brand. 
                      pisicing
                    </p>
                  </div>
                  <div className="news-one__hover-btn-box">
                    <Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="news-one__single">
                <div className="news-one__img-box">
                  <div className="news-one__img">
                    <img src={news4} alt="" />
                    <Link to="/data-as-asset">
                      <span className="news-one__plus" />
                    </Link>
                  </div>
                  <div className="news-one__date">
                    <p>10 April, 2023</p>
                  </div>
                </div>
                <div className="news-one__content">
                  <ul className="news-one__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-tags" />
                      </div>
                      <div className="text">
                        <p>Data Management</p>
                      </div>
                    </li>
                    <li>
                      <span>/</span>
                      <div className="icon">
                        <span className="fas fa-comments" />
                      </div>
                      <div className="text">
                        <p>2 Comments</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="news-one__title">
                    <Link to="/data-as-asset">
                      Data Governance Best Practices: Managing Data as an Asset
                    </Link>
                  </h3>
                  <p className="news-one__text">
                    Data governance refers to the management framework and processes that ensure high data quality, privacy, and security.
                  </p>
                </div>
                <div className="news-one__hover">
                  <div className="news-one__hover-content">
                    <h3 className="news-one__hover-title">
                      <Link to="/data-as-asset">
                        Data Governance Best Practices: Managing Data as an Asset
                      </Link>
                    </h3>
                    <p className="news-one__hover-text">
                      In today's digital age, data has become one of the most valuable assets for businesses across various industries. 
                    </p>
                  </div>
                  <div className="news-one__hover-btn-box">
                    <Link to="/data-as-asset">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="news-one__single">
                <div className="news-one__img-box">
                  <div className="news-one__img">
                    <img src={news5} alt="" />
                    <Link to="/the-fundamental-rules-for-running-a-small-web-design-business">
                      <span className="news-one__plus" />
                    </Link>
                  </div>
                  <div className="news-one__date">
                    <p>1st May, 2023</p>
                  </div>
                </div>
                <div className="news-one__content">
                  <ul className="news-one__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-tags" />
                      </div>
                      <div className="text">
                        <p>Web &amp; Brand Design</p>
                      </div>
                    </li>
                    <li>
                      <span>/</span>
                      <div className="icon">
                        <span className="fas fa-comments" />
                      </div>
                      <div className="text">
                        <p>2 Comments</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="news-one__title">
                    <Link to="/the-fundamental-rules-for-running-a-small-web-design-business">The Fundamental Rules for Running a Small Web Design Business</Link>
                  </h3>
                  <p className="news-one__text">
                     By knowing your audience, honing your skills, and maintaining professionalism, 
                     you'll be well on your way to building a successful web design venture.<br />
                  </p>
                </div>
                <div className="news-one__hover">
                  <div className="news-one__hover-content">
                    <h3 className="news-one__hover-title">
                      <Link to="/the-fundamental-rules-for-running-a-small-web-design-business">
                        The Fundamental Rules for Running a Small Web Design Business
                      </Link>
                    </h3>
                    <p className="news-one__hover-text">
                      Starting a web design business can be an exciting and potentially lucrative venture, 
                      but it also comes with its fair share of challenges. If you're looking to dive into the world of web design, 
                      understanding the basic rules that will help you succeed is essential. 
                    </p>
                  </div>
                  <div className="news-one__hover-btn-box">
                    <Link to="/the-fundamental-rules-for-running-a-small-web-design-business">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="news-one__single">
                <div className="news-one__img-box">
                  <div className="news-one__img">
                    <img src={news6} alt="" />
                    <Link to="/responsive-design-and-brand-consistency-across-devices">
                      <span className="news-one__plus" />
                    </Link>
                  </div>
                  <div className="news-one__date">
                    <p>10 May, 2023</p>
                  </div>
                </div>
                <div className="news-one__content">
                  <ul className="news-one__meta list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-tags" />
                      </div>
                      <div className="text">
                        <p>Web &amp; Brand Design</p>
                      </div>
                    </li>
                    <li>
                      <span>/</span>
                      <div className="icon">
                        <span className="fas fa-comments" />
                      </div>
                      <div className="text">
                        <p>2 Comments</p>
                      </div>
                    </li>
                  </ul>
                  <h3 className="news-one__title">
                    <Link to="/responsive-design-and-brand-consistency-across-devices">
                      Responsive Design and Brand Consistency Across Devices
                    </Link>
                  </h3>
                  <p className="news-one__text">
                    In a world where digital touchpoints are ever-expanding, responsive design is the linchpin for maintaining brand consistency.
                  </p>
                </div>
                <div className="news-one__hover">
                  <div className="news-one__hover-content">
                    <h3 className="news-one__hover-title">
                      <Link to="/responsive-design-and-brand-consistency-across-devices">
                        Responsive Design and Brand Consistency Across Devices
                      </Link>
                    </h3>
                    <p className="news-one__hover-text">
                      In this blog post, we'll delve into the world of responsive design and how it can help you maintain brand consistency seamlessly.
                    </p>
                  </div>
                  <div className="news-one__hover-btn-box">
                    <Link to="/responsive-design-and-brand-consistency-across-devices">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default NewsPage
