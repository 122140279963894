import React from 'react'

function GoogleMap() {
  return (
    <>
      <section className="google-map-two">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.9837056969277!2d9.6961776!3d4.023731500000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106113f9ae020e77%3A0x7c06e5d7cd22286f!2sR%C3%A9sidence%20Kassap!5e0!3m2!1sfr!2scm!4v1694107386613!5m2!1sfr!2scm" className="google-map__two" allowFullScreen="" />
      </section>
    </>
  )
}

export default GoogleMap
