import React from 'react'
import Header from '../HeaderOne/Main'
import TheFundamentalRulesforRunningaSmallWebDesignBusinessDetail from '../MainNewsDetails/TheFundamentalRulesforRunningaSmallWebDesignBusinessDetail'
import PageHeaderTheFundamentalRulesforRunningaSmallWebDesignBusiness from '../MainNewsDetails/PageHeaderTheFundamentalRulesforRunningaSmallWebDesignBusiness'

function Main() {
  return (
    <>
      <Header />
      <PageHeaderTheFundamentalRulesforRunningaSmallWebDesignBusiness />
      <TheFundamentalRulesforRunningaSmallWebDesignBusinessDetail />
    </>
  )
}

export default Main
