import React from 'react'
import Header from '../HeaderOne/Main'
import DataAsAssets from '../MainNewsDetails/DataAsAssets'
import PageHeader from '../MainNewsDetails/PageHeader'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <DataAsAssets />
    </>
  )
}

export default Main
