import React from 'react'

function FeatureThree() {
  return (
    <>
      <section className="feature-three">
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="feature-three__single">
                <div className="feature-three__icon">
                  <span className="icon-handshake" />
                </div>
                <h3 className="feature-three__title">Consulting</h3>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="feature-three__single">
                <div className="feature-three__icon">
                  <span className="icon-coins" />
                </div>
                <h3 className="feature-three__title">General</h3>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="feature-three__single">
                <div className="feature-three__icon">
                  <span className="icon-bonus" />
                </div>
                <h3 className="feature-three__title">Finance</h3>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="feature-three__single">
                <div className="feature-three__icon">
                  <span className="icon-entrepreneur" />
                </div>
                <h3 className="feature-three__title">Experts</h3>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="feature-three__single">
                <div className="feature-three__icon">
                  <span className="icon-fingerprint-scan" />
                </div>
                <h3 className="feature-three__title">Legal</h3>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6">
              <div className="feature-three__single">
                <div className="feature-three__icon">
                  <span className="icon-account" />
                </div>
                <h3 className="feature-three__title">Account</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default FeatureThree
