import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../assets/images/backgrounds/services-one-bg.png'
import datastrategy from '../../assets/images/services/data-strategy-2.jpg'
import datamanagement from '../../assets/images/services/data-management-2.jpg'
import intelligenceimg from '../../assets/images/services/data-intelligence-2.jpg'
import webimg from '../../assets/images/services/web-design-2.jpg'
import datastrategyico from '../../assets/images/content-icon/data_strategy_icon.png'
import datamanagementico from '../../assets/images/content-icon/data_management_icon.png'
import dataintelligenceico from '../../assets/images/content-icon/data_intelligence_icon.png'
import webico from '../../assets/images/content-icon/web-design-icon.png'


function ServiceOne() {
  return (
    <>
      <section className="services-one">
        <div className="services-one__bg" style={{ backgroundImage: "url("+(service1)+")" }} ></div>
        <div className="container">
          <div className="services-one__top">
            <div className="row">
              <div className="col-xl-7 col-lg-6">
                <div className="services-one__left">
                  <div className="section-title text-left">
                    <div className="section-title__tagline-box">
                      <span className="section-title__tagline">what we’re doing</span>
                    </div>
                    <h2 className="section-title__title">
                      Offering the Data Analytics
                      <br /> &amp; Web Development <span>Tools</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="services-one__right">
                  <p className="services-one__text">
                    We offer a wide range of data analytics software  
                    <br /> and solutions designed specifically for companies
                    looking to improve their performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="services-one__bottom">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/">Data Strategy</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src={datastrategy} alt="" />
                    </div>
                    <div className="services-one__icon">
                      <img src={datastrategyico} alt="data strategy icon" />
                    </div>
                  </div>
                  <div className="services-one__bottomtext-box">
                    <p className="services-one__bottomtext">
                      Data maturity assessment<br />
                      Data acculturation<br />
                      Data/IA governance plan
                    </p>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/">Data Management</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src={datamanagement} alt="" />
                    </div>
                    <div className="services-one__icon">
                      <img src={datamanagementico} alt="data management icon" />
                    </div>
                  </div>
                  <div className="services-one__bottomtext-box">
                    <p className="services-one__bottomtext">
                      Big Data and real-time infrastructure<br />
                      Data Quality &amp; Data Catalog
                    </p>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/">Data Intelligence</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src={intelligenceimg} alt="" />
                    </div>
                    <div className="services-one__icon">
                      <img src={dataintelligenceico} alt="data intelligence icon" />
                    </div>
                  </div>
                  <div className="services-one__bottomtext-box">
                    <p className="services-one__bottomtext">
                      Data science<br />
                      Business intelligence<br />
                      <br />
                    </p>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms" >
                <div className="services-one__single">
                  <div className="services-one__title-box">
                    <h3 className="services-one__title">
                      <Link to="/">Web &amp; Brand Development</Link>
                    </h3>
                  </div>
                  <div className="services-one__img-box">
                    <div className="services-one__img">
                      <img src={webimg} alt="" />
                    </div>
                    <div className="services-one__icon">
                      <img src={webico} alt="web icon" />
                    </div>
                  </div>
                  <div className="services-one__bottomtext-box">
                    <p className="services-one__bottomtext">
                      Web design<br />
                      Analytics tools<br />
                      <br />
                    </p>
                  </div>
                  <div className="services-one__read-more">
                    <Link to="/">
                      Read More
                      <span className="icon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServiceOne
