import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainFAQ/PageHeader'
import FeatureThree from '../MainFAQ/FeatureThree'
import FaqSearch from '../MainFAQ/FaqSearch'
import FaqPage from '../MainFAQ/FaqPage'

function Main() {
  return (
    <>
      <Header/>
      <PageHeader/>
      <FeatureThree/>
      <FaqSearch/>
      <FaqPage/>
    </>
  )
}

export default Main
