import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainTeam/PageHeader'
import TeamPage from '../MainTeam/TeamPage'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <TeamPage />
    </>
  )
}

export default Main
