import React from 'react'
import ServiceDetails from '../MainPortfolioMngmt/ServiceDetails'
import Header from '../HeaderOne/Main'
import Pageheader from '../MainPortfolioMngmt/Pageheader'

function Main() {
  return (
    <>
      <Header />
      <Pageheader />
      <ServiceDetails />
    </>
  )
}

export default Main
