import React from 'react'
import { Link } from 'react-router-dom'

import aboutheader from "../../assets/images/backgrounds/page-header-bg.jpg"
import pageshape1 from '../../assets/images/shapes/page-header-shape-1.png'
import pageshape2 from '../../assets/images/shapes/page-header-shape-2.png'
import pageshape3 from '../../assets/images/shapes/page-header-shape-3.png'

function PageHeader() {
  return (
    <>
      <section className="page-header">
        <div className="page-header__bg" style={{ backgroundImage: "url("+(aboutheader)+")" }} ></div>
        <div className="page-header__shape-2 float-bob-x">
          <img src={pageshape2} alt="" />
        </div>
        <div className="page-header__shape-1 float-bob-y">
          <img src={pageshape1} alt="" />
        </div>
        <div className="page-header__shape-3 float-bob-x">
          <img src={pageshape3} alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <h2>About</h2>
            <div className="thm-breadcrumb__inner">
              <ul className="thm-breadcrumb list-unstyled">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>/</span>
                </li>
                <li>About</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PageHeader
