import React from 'react'
import { Link } from 'react-router-dom'
import firstabout from '../../assets/images/resources/about-one-img-2.jpg'
import secondabout from '../../assets/images/resources/about-one-img-1.jpg'
import shape1 from '../../assets/images/shapes/about-one-shape-1.png'
import shape2 from '../../assets/images/shapes/about-one-shape-2.png'
import shape3 from '../../assets/images/shapes/about-one-shape-3.png'
import integrityico from '../../assets/images/content-icon/integrity-color.png'
import confidentialityico from '../../assets/images/content-icon/confidentiality-color.png'
import innovationico from '../../assets/images/content-icon/innovation_color.png'
import commitmentico from '../../assets/images/content-icon/commitment-color.png'

function Aboutone() {
    return (
        <>
            <section className="about-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-one__left">
                                <div className="about-one__img-box wow slideInLeft" data-wow-delay="100ms"
                                    data-wow-duration="2500ms">
                                    <div className="about-one__img">
                                        <img src={firstabout} alt="" />
                                        <div className="about-one__shape-1 float-bob-x">
                                            <img src={shape1} alt="" />
                                        </div>
                                        <div className="about-one__shape-2 img-bounce">
                                            <img src={shape2} alt="" />
                                        </div>
                                    </div>
                                    <div className="about-one__img-2">
                                        <img src={secondabout} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-one__right">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        <span className="section-title__tagline">Welcome to Zemtsi Data</span>
                                    </div>
                                    <h2 className="section-title__title">Solution to Turn your Data into
                                        Real <span>Performance Lever</span></h2>
                                </div>
                                <p className="about-one__text">We are a visionary data analytics company, combining expertise, innovation and engaging visualization to transform data into actionable insights and deliver predictive insights to its clients.</p>
                                <div className="about-one__points-and-experience">
                                    <div className="about-one__points-box">
                                        <ul className="about-one__points-list list-unstyled">
                                            <li>
                                                <div className="icon">
                                                    <img src={integrityico} alt="integrity icon" />
                                                </div>
                                                <div className="text">
                                                    <p>Integrity</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <img src={confidentialityico} alt="confidentiality icon" />
                                                </div>
                                                <div className="text">
                                                    <p>Confidentiality</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="about-one__points-list list-unstyled">
                                            <li>
                                                <div className="icon">
                                                    <img src={innovationico} alt="innovation icon" />
                                                </div>
                                                <div className="text">
                                                    <p>Innovation</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <img src={commitmentico} alt="commitment icon" />
                                                </div>
                                                <div className="text">
                                                    <p>Honouring Commitments</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="about-one__experience-box">
                                        <div className="about-one__experience-icon">
                                            <span className="icon-certificate"></span>
                                        </div>
                                        <div className="about-one__experience-text">
                                            <p>+5 Years of Experience</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-one__btn-box">
                                    <div className="about-one__shape-3 float-bob-x">
                                        <img src={shape3} alt="" />
                                    </div>
                                    <Link to="/about" className="about-one__btn thm-btn">Discover More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutone
