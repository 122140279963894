import React from 'react'
import { Link } from 'react-router-dom'

function Servicespage() {
  return (
    <>
      <section className="services-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src="assets/images/services/services-page-1-1.jpg" alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-pie-chart" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/capital-market">Capital Market</Link>
                  </h3>
                  <p className="services-page__text">
                    At vero eos et accusamus et iustoodio digni goikussimos ducimus
                    qui blanp ditiis praesum voluum.
                  </p>
                  <Link to="/capital-market" className="services-page__read-more">
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-page-hover-img-1.jpg)"
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-pie-chart" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/capital-market">Capital Market</Link>
                      </h3>
                      <p className="services-page__hover-text">
                        At vero eos et accusamus et iustoodio digni goikussimos
                        ducimus qui blanp ditiis praesum voluum.
                      </p>
                      <Link
                        to="/capital-market"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src="assets/images/services/services-page-1-2.jpg" alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-insurance" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/insurance">Insurance</Link>
                  </h3>
                  <p className="services-page__text">
                    At vero eos et accusamus et iustoodio digni goikussimos ducimus
                    qui blanp ditiis praesum voluum.
                  </p>
                  <Link to="/insurance" className="services-page__read-more">
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-page-hover-img-2.jpg)"
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-insurance" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/services-details">Insurance</Link>
                      </h3>
                      <p className="services-page__hover-text">
                        At vero eos et accusamus et iustoodio digni goikussimos
                        ducimus qui blanp ditiis praesum voluum.
                      </p>
                      <Link
                        to="/insurance"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src="assets/images/services/services-page-1-3.jpg" alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-money-bag" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/mutual-funds">Mutual Funds</Link>
                  </h3>
                  <p className="services-page__text">
                    At vero eos et accusamus et iustoodio digni goikussimos ducimus
                    qui blanp ditiis praesum voluum.
                  </p>
                  <Link to="/mutual-funds" className="services-page__read-more">
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-page-hover-img-3.jpg)"
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-money-bag" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/mutual-funds">Mutual Funds</Link>
                      </h3>
                      <p className="services-page__hover-text">
                        At vero eos et accusamus et iustoodio digni goikussimos
                        ducimus qui blanp ditiis praesum voluum.
                      </p>
                      <Link to="#" className="services-page__hover-read-more">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src="assets/images/services/services-page-1-4.jpg" alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-profile" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/portfolio-management">Portfolio Management</Link>
                  </h3>
                  <p className="services-page__text">
                    At vero eos et accusamus et iustoodio digni goikussimos ducimus
                    qui blanp ditiis praesum voluum.
                  </p>
                  <Link
                    to="/portfolio-management"
                    className="services-page__read-more"
                  >
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-page-hover-img-4.jpg)"
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-profile" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/portfolio-management">Portfolio Management</Link>
                      </h3>
                      <p className="services-page__hover-text">
                        At vero eos et accusamus et iustoodio digni goikussimos
                        ducimus qui blanp ditiis praesum voluum.
                      </p>
                      <Link
                        to="/portfolio-management"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src="assets/images/services/services-page-1-5.jpg" alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-income" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/fixed-income">Fixed Income</Link>
                  </h3>
                  <p className="services-page__text">
                    At vero eos et accusamus et iustoodio digni goikussimos ducimus
                    qui blanp ditiis praesum voluum.
                  </p>
                  <Link to="/fixed-income" className="services-page__read-more">
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-page-hover-img-5.jpg)"
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-income" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/fixed-income">Fixed Income</Link>
                      </h3>
                      <p className="services-page__hover-text">
                        At vero eos et accusamus et iustoodio digni goikussimos
                        ducimus qui blanp ditiis praesum voluum.
                      </p>
                      <Link
                        to="/fixed-income"
                        className="services-page__hover-read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="services-page__single">
                <div className="services-page__img-box">
                  <div className="services-page__img">
                    <img src="assets/images/services/services-page-1-6.jpg" alt="" />
                  </div>
                  <div className="services-page__icon">
                    <span className="icon-mortgage-loan" />
                  </div>
                </div>
                <div className="services-page__content">
                  <h3 className="services-page__title">
                    <Link to="/loans">Loans</Link>
                  </h3>
                  <p className="services-page__text">
                    At vero eos et accusamus et iustoodio digni goikussimos ducimus
                    qui blanp ditiis praesum voluum.
                  </p>
                  <Link to="/loans" className="services-page__read-more">
                    Read More
                  </Link>
                </div>
                <div className="services-page__hover-single">
                  <div
                    className="services-page__hover-img"
                    style={{
                      backgroundImage:
                        "url(assets/images/services/services-page-hover-img-6.jpg)"
                    }}
                  ></div>
                  <div className="services-page__hover-content-box">
                    <div className="services-page__hover-icon">
                      <span className="icon-mortgage-loan" />
                    </div>
                    <div className="services-page__hover-content">
                      <h3 className="services-page__hover-title">
                        <Link to="/loans">Loans</Link>
                      </h3>
                      <p className="services-page__hover-text">
                        At vero eos et accusamus et iustoodio digni goikussimos
                        ducimus qui blanp ditiis praesum voluum.
                      </p>
                      <Link to="/loans" className="services-page__hover-read-more">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Servicespage
