import React from 'react'
import Header from '../HeaderOne/Main'


function Main() {
  return (
    <>
      <Header/>
    </>
  )
}

export default Main
