import React from 'react'
import { Link } from 'react-router-dom'
import NumberCounter from 'number-counter';
import counterbg from '../../assets/images/backgrounds/counter-one-bg.jpg'


function CounterOne() {
    return (
        <>
            <section className="counter-one">
                <div className="counter-one__inner">
                    <div className="counter-one__shadow"></div>
                    <div className="counter-one__bg" style={{ backgroundImage: 'url('+(counterbg)+')' }} />
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="counter-one__left">
                                    <div className="section-title text-left">
                                        <div className="section-title__tagline-box">
                                            <span className="section-title__tagline">fun facts</span>
                                        </div>
                                        <h2 className="section-title__title">Zemtsi Data Funfacts
                                            <br /> in Great <span>Numbers</span></h2>
                                    </div>
                                    <p className="counter-one__text">Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative 
                                    approaches to corporate strategy data foster to collaborative thinking.</p>
                                    <div className="section-title2 text-left">
                                        <h3 className="section-title__title2">Your Data, Our Commitment,
                                            <br /><span>Let's Turn it into Gold.</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7">
                                <div className="counter-one__right">
                                    <ul className="counter-one__count-box list-unstyled">
                                        <li>
                                            <div className="counter-one__icon">
                                                <span className="icon-checking"></span>
                                            </div>
                                            <div className="counter-one__count count-box">
                                                <h3>  <NumberCounter className="count-text" data-count={120} end={100} delay={1.0}>00</NumberCounter> </h3>
                                            </div>
                                            <p className="counter-one__text">Projects Completed</p>
                                        </li>
                                        <li>
                                            <div className="counter-one__icon">
                                                <span className="icon-recommend"></span>
                                            </div>
                                            <div className="counter-one__count count-box">
                                                <h3>  <NumberCounter className="count-text" data-count={320} end={20} delay={1.0}>00</NumberCounter> </h3>
                                            </div>
                                            <p className="counter-one__text">Satisfied Customers</p>
                                        </li>
                                        <li>
                                            <div className="counter-one__icon">
                                                <span className="icon-consulting"></span>
                                            </div>
                                            <div className="counter-one__count count-box">
                                                <h3>  <NumberCounter className="count-text" data-count={320} end={10} delay={1.0}>00</NumberCounter> </h3>
                                            </div>
                                            <p className="counter-one__text">Expert Consultants</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="counter-one__bottom">
                    <div className="container">
                        <div className="counter-one__bottom-inner">
                            <p className="counter-one__bottom-text">Need best business to turn your data into a real performance lever ? <Link
                                to="/contact">Send a Request</Link></p>
                            <div className="counter-one__call-box">
                                <p>Call Now <Link to="tel:+237653364045">(+237)653 364 045</Link></p>
                                <div className="counter-one__call-icon">
                                    <span className="icon-telephone-1"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CounterOne
