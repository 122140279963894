import React from 'react'
import { Link } from 'react-router-dom'

import wilfred from '../../assets/images/team/team-1-1.jpg'
import boris from '../../assets/images/team/team-1-2.jpg'
import annie from '../../assets/images/team/team-1-4.jpg'

function TeamOne() {
  return (
    <>
      <section className="team-one about-page-team">
        <div className="container">
          <div className="team-one__top">
            <div className="row">
              <div className="col-xl-7 col-lg-6">
                <div className="team-one__left">
                  <div className="section-title text-left">
                    <div className="section-title__tagline-box">
                      <span className="section-title__tagline">meet our team</span>
                    </div>
                    <h2 className="section-title__title">
                      Meet the People Behind
                      <br /> the High <span>Success</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="team-one__right">
                  <p className="team-one__text">
                    We have a team of experts with a strong customer culture, always ready to serve you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="team-one__bottom">
            <div className="row">

              <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={wilfred} alt="" />
                    </div>
                    <div className="team-one__hover-content">
                      <div className="team-one__hover-arrow-box">
                        <Link to="/about" className="team-one__hover-arrow">
                          <span className="fas fa-share-alt" />
                        </Link>
                        <ul className="list-unstyled team-one__social">
                          <li><Link to="https://www.linkedin.com/in/wilfred-zemtsop-789344176/"><i className="fab fa-linkedin"></i></Link></li>
                              <li><Link to="https://www.facebook.com/zemtsop/"><i className="fab fa-facebook"></i></Link></li>
                              <li><Link to="tel:695633783"><i className="fab fa-whatsapp"></i></Link></li>
                              <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                        </ul>
                      </div>
                      <h3 className="team-one__hover-title">
                        <Link to="/about">Wilfred ZEMTSOP</Link>
                      </h3>
                      <p className="team-one__hover-sub-title">CEO & Founder</p>
                      <p className="team-one__hover-text">
                      Expert DATA Strategy & NEBOSH Certified
                      </p>
                    </div>
                  </div>
                  <div className="team-one__content">
                    <div className="team-one__arrow-box">
                      <Link to="/about" className="team-one__arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                    </div>
                    <h3 className="team-one__title">
                      <Link to="/about">Wilfred ZEMTSOP</Link>
                    </h3>
                    <p className="team-one__sub-title">CEO & Founder</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={annie} alt="" />
                    </div>
                    <div className="team-one__hover-content">
                      <div className="team-one__hover-arrow-box">
                        <Link to="/about" className="team-one__hover-arrow">
                          <span className="fas fa-share-alt" />
                        </Link>
                        <ul className="list-unstyled team-one__social">
                        <li><Link to="https://www.linkedin.com/in/annie-rosanne-womey-bab648208/"><i className="fab fa-linkedin"></i></Link></li>
                          <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                          <li><Link to="tel:+23790310615"><i className="fab fa-whatsapp"></i></Link></li>
                          <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                        </ul>
                      </div>
                      <h3 className="team-one__hover-title">
                        <Link to="/about">Annie WOMEY</Link>
                      </h3>
                      <p className="team-one__hover-sub-title">QHSE Consultant</p>
                      <p className="team-one__hover-text">
                        Quality Engineer
                      </p>
                    </div>
                  </div>
                  <div className="team-one__content">
                    <div className="team-one__arrow-box">
                      <Link to="/about" className="team-one__arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                    </div>
                    <h3 className="team-one__title">
                      <Link to="/about">Annie WOMEY</Link>
                    </h3>
                    <p className="team-one__sub-title">QHSE Consultant</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                <div className="team-one__single">
                  <div className="team-one__img-box">
                    <div className="team-one__img">
                      <img src={boris} alt="" />
                    </div>
                    <div className="team-one__hover-content">
                      <div className="team-one__hover-arrow-box">
                        <Link to="/about" className="team-one__hover-arrow">
                          <span className="fas fa-share-alt" />
                        </Link>
                        <ul className="list-unstyled team-one__social">
                          <li><Link to="https://cm.linkedin.com/in/boris-zifack/"><i className="fab fa-linkedin"></i></Link></li>
                          <li><Link to="https://www.facebook.com/boris.zifack/"><i className="fab fa-facebook"></i></Link></li>
                          <li><Link to="tel:681110863"><i className="fab fa-whatsapp"></i></Link></li>
                          <li><Link to="https://www.instagram.com/boris_zif/"><i className="fab fa-instagram"></i></Link></li>
                        </ul>
                      </div>
                      <h3 className="team-one__hover-title">
                        <Link to="/about">Boris ZIFACK</Link>
                      </h3>
                      <p className="team-one__hover-sub-title">Marketing Consultant</p>
                      <p className="team-one__hover-text">
                        Graphic Designer <br />Web Designer <br />Brand Marketer <br />Copywriter
                      </p>
                    </div>
                  </div>
                  <div className="team-one__content">
                    <div className="team-one__arrow-box">
                      <Link to="/about" className="team-one__arrow">
                        <span className="fas fa-share-alt" />
                      </Link>
                    </div>
                    <h3 className="team-one__title">
                      <Link to="/about">Boris ZIFACK</Link>
                    </h3>
                    <p className="team-one__sub-title">Marketing Consultant</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TeamOne
