import React from 'react'
import Header from '../HeaderOne/Main'
import BuildinganEffectiveDataStrategyforBusinessSuccessDetail from '../MainNewsDetails/BuildinganEffectiveDataStrategyforBusinessSuccessDetail'
import PageHeaderBuildinganEffectiveDataStrategyforBusinessSuccess from '../MainNewsDetails/PageHeaderBuildinganEffectiveDataStrategyforBusinessSuccess'

function Main() {
  return (
    <>
      <Header />
      <PageHeaderBuildinganEffectiveDataStrategyforBusinessSuccess />
      <BuildinganEffectiveDataStrategyforBusinessSuccessDetail />
    </>
  )
}

export default Main
