import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainContact/PageHeader'
import ContactPage from '../MainContact/ContactPage'
import GoogleMap from '../MainContact/GoogleMap'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <ContactPage />
      <GoogleMap />
    </>
  )
}

export default Main
