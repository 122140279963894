import React from 'react'
import { Link } from 'react-router-dom'
import aboutfourshape from '../../assets/images/shapes/about-four-shape-1.png'
import aboutfourimg1 from '../../assets/images/resources/about-four-img-1.jpg'
import aboutfourimg2 from '../../assets/images/resources/about-four-img-2.jpg'

function AboutFour() {
  return (
    <>
      <section className="about-four">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-four__left">
                <div className="about-four__img-box">
                  <div className="about-four__img">
                    <img src={aboutfourimg1} alt="" />
                  </div>
                  <div className="about-four__img-two">
                    <img src={aboutfourimg2} alt="" />
                  </div>
                  <div className="about-four__shape-1 img-bounce" />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="about-four__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">Welcome to Zemtsi Data</span>
                  </div>
                  <h2 className="section-title__title">Solution to Turn your Data into
                    Real <span>Performance Lever</span>
                    </h2>
                </div>
                <p className="about-four__text">
                We are a visionary data analytics company, combining expertise, innovation and engaging visualization 
                to transform data into actionable insights and deliver predictive insights to its clients.<br />
                We are guided by:
                </p>
                <ul className="about-four__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Integrity</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Confidentiality</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Innovation</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fa fa-check" />
                    </div>
                    <div className="text">
                      <p>Honouring Commitments</p>
                    </div>
                  </li>
                </ul>
                <div className="about-four__btn-box">
                  <Link to="/contact" className="thm-btn about-four__btn">
                    Get in touch
                  </Link>
                  <div className="about-four__shape-2 float-bob-x">
                    <img src={aboutfourshape} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutFour
