import React from 'react'
import Header from '../HeaderOne/Main'
import ResponsiveDesignandBrandConsistencyAcrossDevicesDetail from '../MainNewsDetails/ResponsiveDesignandBrandConsistencyAcrossDevicesDetail'
import PageHeaderResponsiveDesignandBrandConsistencyAcrossDevices from '../MainNewsDetails/PageHeaderResponsiveDesignandBrandConsistencyAcrossDevices'

function Main() {
  return (
    <>
      <Header />
      <PageHeaderResponsiveDesignandBrandConsistencyAcrossDevices />
      <ResponsiveDesignandBrandConsistencyAcrossDevicesDetail />
    </>
  )
}

export default Main
