import React from 'react'
import { Link } from 'react-router-dom'

function ExpectationOne() {
  return (
    <>
      <section className="expectation-one">
        <div className="expectation-one__bg jarallax" data-jarallax="" data-speed="0.2" data-imgposition="50% 0%" style={{ backgroundImage: "url(assets/images/backgrounds/expectation-one-bg.jpg)" }} />
        <div className="section-title text-center">
          <div className="section-title__tagline-box">
            <span className="section-title__tagline">Mission</span>
          </div>
          <h2 className="section-title__title">
            Our Mission is to  
            <br /> Turn your Data into a <span>Performance Lever</span>
          </h2>
        </div>
        {/*<div className="expectation-one__inner">
          <div className="container">
            <ul className="expectation-one__points list-unstyled">
              <li>
                <div className="icon">
                  <span className="icon-strategy" />
                </div>
                <h3 className="expectation-one__title">
                  <Link to="/about">Saving and Strategy</Link>
                </h3>
                <p className="expectation-one__text">
                  There are many variations of passages of available but the majority
                  have suffered alteration in some form injected randomised words.
                </p>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-conversation" />
                </div>
                <h3 className="expectation-one__title">
                  <Link to="/about">HR Business Consulting</Link>
                </h3>
                <p className="expectation-one__text">
                  There are many variations of passages of available but the majority
                  have suffered alteration in some form injected randomised words.
                </p>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-planning" />
                </div>
                <h3 className="expectation-one__title">
                  <Link to="/about">Business Planning</Link>
                </h3>
                <p className="expectation-one__text">
                  There are many variations of passages of available but the majority
                  have suffered alteration in some form injected randomised words.
                </p>
              </li>
            </ul>
          </div>
        </div>*/}
      </section>
    </>
  )
}

export default ExpectationOne
