import React from 'react'
import Header from '../HeaderOne/Main'
import PageHeader from '../MainMutualFunds/PageHeader'
import ServiceDetails from '../MainMutualFunds/ServiceDetails'

function Main() {
  return (
    <>
      <Header />
      <PageHeader />
      <ServiceDetails />
    </>
  )
}

export default Main
