import React from 'react'
import { Link } from 'react-router-dom'
import news1 from '../../assets/images/blog/news-1-1.jpg'
import news2 from '../../assets/images/blog/news-1-2.jpg'
import news3 from '../../assets/images/blog/news-1-3.jpg'

function NewsOne() {
  return (
    <>
      <section className="news-one">
            <div className="container">
                <div className="section-title text-center">
                    <div className="section-title__tagline-box">
                        <span className="section-title__tagline">Our News Updates</span>
                    </div>
                    <h2 className="section-title__title">Latest Articles &
                        <br/> News from the <span>Blogs</span></h2>
                </div>
                <div className="row">
                
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                        <div className="news-one__single">
                            <div className="news-one__img-box">
                                <div className="news-one__img">
                                    <img src={news1} alt=""/>
                                    <Link to="/the-evolution-of-data-management-trends-and-challenges-detail">
                                        <span className="news-one__plus"></span>
                                    </Link>
                                </div>
                                <div className="news-one__date">
                                    <p>30 March, 2023</p>
                                </div>
                            </div>
                            <div className="news-one__content">
                                <ul className="news-one__meta list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="fas fa-tags"></span>
                                        </div>
                                        <div className="text">
                                            <p>Data Management</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span>/</span>
                                        <div className="icon">
                                            <span className="fas fa-comments"></span>
                                        </div>
                                        <div className="text">
                                            <p>2 Comments</p>
                                        </div>
                                    </li>
                                </ul>
                                <h3 className="news-one__title"><Link to="/the-evolution-of-data-management-trends-and-challenges-detail">The Evolution of Data Management: Trends and Challenges</Link></h3>
                                <p className="news-one__text"> 
                                    The evolution of data management has been a remarkable journey from manual data processing to AI-driven insights and beyond.<br />
                                    <br />
                                </p>
                            </div>
                            <div className="news-one__hover">
                                <div className="news-one__hover-content">
                                    <h3 className="news-one__hover-title"><Link to="/the-evolution-of-data-management-trends-and-challenges-detail">The Evolution of Data Management: Trends and Challenges
                                            your business problems</Link></h3>
                                    <p className="news-one__hover-text">
                                        In today's data-driven world, managing and harnessing the power of data has become a critical aspect of business and technological advancement.
                                        
                                    </p>
                                </div>
                                <div className="news-one__hover-btn-box">
                                    <Link to="/the-evolution-of-data-management-trends-and-challenges-detail">Read More<span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
     
                 
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                        <div className="news-one__single">
                            <div className="news-one__img-box">
                                <div className="news-one__img">
                                    <img src={news2} alt=""/>
                                    <Link to="/buildingan-effective-datastrategy-for-business-success">
                                        <span className="news-one__plus"></span>
                                    </Link>
                                </div>
                                <div className="news-one__date">
                                    <p>1st April, 2023</p>
                                </div>
                            </div>
                            <div className="news-one__content">
                                <ul className="news-one__meta list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="fas fa-tags"></span>
                                        </div>
                                        <div className="text">
                                            <p>Data Strategy</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span>/</span>
                                        <div className="icon">
                                            <span className="fas fa-comments"></span>
                                        </div>
                                        <div className="text">
                                            <p>2 Comments</p>
                                        </div>
                                    </li>
                                </ul>
                                <h3 className="news-one__title"><Link to="/buildingan-effective-datastrategy-for-business-success">Building an Effective Data Strategy for Business Success</Link></h3>
                                <p className="news-one__text">
                                    Building a robust data strategy is not only essential but often the differentiating factor between 
                                    companies that thrive and those that struggle. 
                                </p>
                            </div>
                            <div className="news-one__hover">
                                <div className="news-one__hover-content">
                                    <h3 className="news-one__hover-title"><Link to="/buildingan-effective-datastrategy-for-business-success">Building an Effective Data Strategy for Business Success</Link></h3>
                                    <p className="news-one__hover-text">
                                        This article will explore the key components and steps involved in crafting an effective data 
                                        strategy to drive business success.
                                    </p>
                                </div>
                                <div className="news-one__hover-btn-box">
                                    <Link to="/buildingan-effective-datastrategy-for-business-success">Read More<span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
               
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                        <div className="news-one__single">
                            <div className="news-one__img-box">
                                <div className="news-one__img">
                                    <img src={news3} alt=""/>
                                    <Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">
                                        <span className="news-one__plus"></span>
                                    </Link>
                                </div>
                                <div className="news-one__date">
                                    <p>5 April, 2023</p>
                                </div>
                            </div>
                            <div className="news-one__content">
                                <ul className="news-one__meta list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="fas fa-tags"></span>
                                        </div>
                                        <div className="text">
                                            <p>Web &amp; Brand Design</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span>/</span>
                                        <div className="icon">
                                            <span className="fas fa-comments"></span>
                                        </div>
                                        <div className="text">
                                            <p>2 Comments</p>
                                        </div>
                                    </li>
                                </ul>
                                <h3 className="news-one__title"><Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">The Psychology of Web Design: How User Perception Impacts Branding</Link></h3>
                                <p className="news-one__text">
                                    Beyond aesthetics and functionality, the psychology of web design delves into how users perceive and 
                                    interact with websites.
                                </p>
                            </div>
                            <div className="news-one__hover">
                                <div className="news-one__hover-content">
                                    <h3 className="news-one__hover-title"><Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">The Psychology of Web Design: How User Perception Impacts Branding</Link></h3>
                                    <p className="news-one__hover-text">
                                        In the digital age, web design is a powerful tool for shaping user perception and building a compelling brand. 
                                        pisicing
                                    </p>
                                </div>
                                <div className="news-one__hover-btn-box">
                                    <Link to="/the-psychology-of-web-design-how-user-perception-impacts-branding">Read More<span className="icon-right-arrow"></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
        </section>
    </>
  )
}

export default NewsOne
