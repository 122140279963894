import React from 'react'
import { Link } from 'react-router-dom'

function ContactPage() {
  return (
    <>
      <section className="contact-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="contact-page__left">
                <div className="contact-page__form-box">
                  <form action="assets/inc/sendemail.php" className="contact-page__form contact-form-validated" noValidate="novalidate" >
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="contact-page__input-box">
                          <input type="text" placeholder="Your name" name="name" />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="contact-page__input-box">
                          <input type="email" placeholder="Email address" name="email" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="contact-page__input-box text-message-box">
                          <textarea name="message" placeholder="Write message" defaultValue={""} />
                        </div>
                        <div className="contact-page__btn-box">
                          <button type="submit" className="thm-btn contact-page__btn">
                            Send a Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="result" />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="contact-page__right">
                <div className="section-title text-left">
                  <div className="section-title__tagline-box">
                    <span className="section-title__tagline">contact us</span>
                  </div>
                  <h2 className="section-title__title">
                    Have Questions? Contact
                    <br /> with us <span>Anytime</span>
                  </h2>
                </div>
                <ul className="contact-page__points list-unstyled">
                  <li>
                    <div className="icon">
                      <span className="icon-telephone-1" />
                    </div>
                    <div className="text">
                      <p>Have any question?</p>
                      <h3>
                        Free <Link to="tel:+237695633783">(+237)695 633 783</Link>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-email" />
                    </div>
                    <div className="text">
                      <p>Send Email</p>
                      <h3>
                        <Link to="mailto:contact@zemtsidata.com">contact@zemtsidata.com</Link>
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="icon-pin" />
                    </div>
                    <div className="text">
                      <p>Visit anytime</p>
                      <h3>Entrée COGEFAR, Douala, Cameroon</h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactPage
