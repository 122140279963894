import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import zemtsilogo from "../../assets/images/resources/logo-zemtsidata.png"

function Main() {
    const [click, setClick] = useState(false);
    const [active, setActive] = useState(false);
    const [Pages, setPages] = useState(false);
    const [Portfolio, setPortfolio] = useState(false);
    const [News, setNews] = useState(false);
    const [Services, setServices] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [lastscrool, setlastscrool] = useState(0);
    const [show, setShow] = useState();
    const [header, setheader] = useState(false);


    const handleScroll = () => {

        const offset = window.scrollY;
        setlastscrool(offset);

        if (offset > 1000 && offset < lastscrool) {
            setScrolled(true);
        }

        else if (offset > 1000) {
        }

        else if (offset > 200) {
            setScrolled(true);
        }

        else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    return (
        <>
            <header className="main-header-two">
        <nav className="main-menu main-menu-two">
          <div className="main-menu-two__wrapper">
            <div className="main-menu-two__wrapper-inner">
              <div className="main-menu-two__logo">
                <Link to="/">
                  <img src={zemtsilogo} alt="" />
                </Link>
              </div>
              <div className="main-menu-two__wrapper-inner-content">
                <div className="main-menu-two__top">
                  <div className="main-menu-two__top-inner">
                    <div className="main-menu-two__top-left">
                      <ul className="list-unstyled main-menu-two__contact-list">
                        <li>
                          <div className="icon">
                            <i className="fas fa-envelope" />
                          </div>
                          <div className="text">
                            <p>
                              <Link to="mailto:contact@zemtsidata.com">
                                contact@zemtsidata.com
                              </Link>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="fas fa-map-marker" />
                          </div>
                          <div className="text">
                            <p>Entrée COGEFAR - DOUALA</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="main-menu-two__top-right">
                      <ul className="list-unstyled main-menu-two__top-menu">
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/about">Help</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                      <div className="main-menu-two__social">
                        <Link to="tel:+237653364045">
                          <i className="fab fa-whatsapp" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                        <Link to="https://www.linkedin.com/company/zemtsidata">
                          <i className="fab fa-linkedin" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-menu-two__bottom">
                  <div className="main-menu-two__bottom-inner">
                    <div className="main-menu-two__main-menu-box">
                      <div className="main-menu-two__btn-box">
                        <Link to="/contact" className="thm-btn main-menu-two__btn">
                          Free Consultation
                        </Link>
                      </div>
                      <div className="main-menu-two__main-menu-box-left">
                        <Link to="#" className="mobile-nav__toggler" onClick={() => { setActive(true) }}>
                          <i className="fa fa-bars" onClick={() => { setActive(true) }} />
                        </Link>
                        <ul className="main-menu__list">
                          <li>
                            <Link to="/">Home </Link>
                          </li>
                          <li>
                            <Link to="/about">About</Link>
                          </li>
                          <li className="dropdown">
                            <Link to="/">Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/">Services</Link>
                              </li>
                              <li>
                                <Link to="/">Data strategy</Link>
                              </li>
                              <li>
                                <Link to="/">Data management</Link>
                              </li>
                              <li>
                                <Link to="/">Data intelligence</Link>
                              </li>
                              <li>
                                <Link to="/">
                                  Web & Brand development
                                </Link>
                              </li>
                              <li>
                                <Link to="/">Faqs</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                              <Link to="/">Careers</Link>
                          </li>
                          <li>
                            <Link to="/">Team</Link>
                          </li>
                          <li>
                              <Link to="/">Training</Link>
                          </li>
                          <li>
                            <Link to="/blog">Blog</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="main-menu-two__main-menu-box-right">
                        <div className="main-menu-two__search-box" >
                          <Link to="#" className="main-menu-two__search search-toggler icon-magnifying-glass" onClick={() => setClick(true)} />
                          {click && <div className="search-popup active">
                            <div className="search-popup__overlay search-toggler" onClick={() => setClick(!click)}></div>
                            <div className="search-popup__content">
                              <form action="#">
                                <label htmlFor="search" className="sr-only">search here</label>
                                <input type="text" id="search" placeholder="Search Here..." />
                                <button type="submit" aria-label="search submit" className="thm-btn">
                                  <i className="icon-magnifying-glass"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="main-menu-two__right">
                      <div className="main-menu-two__call">
                        <div className="main-menu-two__call-icon">
                          <span className="icon-telephone" />
                        </div>
                        <div className="main-menu-two__call-content">
                          <p className="main-menu-two__call-sub-title">
                            Call Anytime
                          </p>
                          <h5 className="main-menu-two__call-number">
                            <Link to="tel:+237653364045">(+237)653 364 045</Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className={`stricky-header stricked-menu main-menu ${scrolled && "stricky-fixed"}`}>
        <div className="sticky-header__content">
          <header className="main-header-two">
            <nav className="main-menu main-menu-two">
              <div className="main-menu-two__wrapper">
                <div className="main-menu-two__wrapper-inner">
                  <div className="main-menu-two__logo">
                    <Link to="/">
                      <img src="assets/images/resources/logo-zemtsidata.png" alt="" />
                    </Link>
                  </div>
                  <div className="main-menu-two__wrapper-inner-content">
                    <div className="main-menu-two__top">
                      <div className="main-menu-two__top-inner">
                        <div className="main-menu-two__top-left">
                          <ul className="list-unstyled main-menu-two__contact-list">
                            <li>
                              <div className="icon">
                                <i className="fas fa-envelope" />
                              </div>
                              <div className="text">
                                <p>
                                  <Link to="mailto:contact@zemtsidata.com">
                                    contact@zemtsidata.com
                                  </Link>
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <i className="fas fa-map-marker" />
                              </div>
                              <div className="text">
                                <p>Entrée COGEFAR - DOUALA</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="main-menu-two__top-right">
                          <ul className="list-unstyled main-menu-two__top-menu">
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li>
                              <Link to="/about">Help</Link>
                            </li>
                            <li>
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                          <div className="main-menu-two__social">
                          <Link to="tel:+237653364045">
                          <i className="fab fa-whatsapp" />
                        </Link>
                        <Link to="#">
                          <i className="fab fa-facebook" />
                        </Link>
                        <Link to="https://www.linkedin.com/company/zemtsidata">
                          <i className="fab fa-linkedin" />
                        </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="main-menu-two__bottom">
                      <div className="main-menu-two__bottom-inner">
                        <div className="main-menu-two__main-menu-box">
                          <div className="main-menu-two__btn-box">
                            <Link to="/contact" className="thm-btn main-menu-two__btn">
                              Free Consultation
                            </Link>
                          </div>
                          <div className="main-menu-two__main-menu-box-left">
                            <Link to="#" className="mobile-nav__toggler">
                              <i className="fa fa-bars" />
                            </Link>
                            <Link to="#" className="mobile-nav__toggler" onClick={() => { setActive(true) }}>
                          <i className="fa fa-bars" onClick={() => { setActive(true) }} />
                        </Link>
                        <ul className="main-menu__list">
                          <li className="dropdown">
                            <Link to="/">Home </Link>
                          </li>
                          <li className="dropdown">
                            <Link to="/about">About</Link>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/">Services</Link>
                              </li>
                              <li>
                                <Link to="/">Data strategy</Link>
                              </li>
                              <li>
                                <Link to="/">Data management</Link>
                              </li>
                              <li>
                                <Link to="/">Data intelligence</Link>
                              </li>
                              <li>
                                <Link to="/">
                                  Web & Brand development
                                </Link>
                              </li>
                              <li>
                                <Link to="/">Faqs</Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                              <Link to="/">Careers</Link>
                          </li>
                          <li>
                            <Link to="/">Team</Link>
                          </li>
                          <li>
                              <Link to="/">Training</Link>
                          </li>
                          <li>
                            <Link to="/blog">Blog</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                          </div>
                          <div className="main-menu-two__main-menu-box-right">
                            <div className="main-menu-two__search-box">
                              <Link
                                to="#"
                                className="main-menu-two__search search-toggler icon-magnifying-glass"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="main-menu-two__right">
                          <div className="main-menu-two__call">
                            <div className="main-menu-two__call-icon">
                              <span className="icon-telephone" />
                            </div>
                            <div className="main-menu-two__call-content">
                              <p className="main-menu-two__call-sub-title">
                                Call Anytime
                              </p>
                              <h5 className="main-menu-two__call-number">
                                <Link to="tel:+237653364045">(+237)653 364 045</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${active === true ? "expanded" : ""}`}>
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times" onClick={() => { setActive(false) }}></i></span>
          <div className="logo-box">
            <Link to="/" aria-label="logo image"><img src={zemtsilogo} width="135" alt="" /></Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current" onClick={() => setShow(show === true ? false : true)}>
                <Link to="/" className={show ? "expanded" : ""}>Home <button aria-label="dropdown toggler" className={show ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
              </li>
              <li className="dropdown">
                <Link to="/about">About<button aria-label="dropdown toggler"><i className="fa fa-angle-down"></i></button></Link>
              </li>
              {/*<li className="dropdown" onClick={() => setPages(Pages === true ? false : true)}>
                <Link to="#" className={Pages ? "expanded" : ""}>Pages<button aria-label="dropdown toggler" className={Pages ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul className="sub-menu" style={{ display: Pages ? "block" : "none" }}>
                  <li><Link to="/team">Our Team</Link></li>
                  <li><Link to="/team-details">Team Details</Link></li>
                  <li><Link to="/testimonials">Training</Link></li>
                  <li><Link to="/careers">Careers</Link></li>
                  <li><Link to="/faq">Faqs</Link></li>
                </ul>
                </li>*/}
              <li className="dropdown" onClick={() => setServices(Services === true ? false : true)}>
                <Link to="#" className={Services ? "expanded" : ""}>Services<button aria-label="dropdown toggler" className={Services ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul className="sub-menu" style={{ display: Services ? "block" : "none" }}>
                  <li><Link to="/">Services</Link></li>
                  <li><Link to="/">Data Strategy</Link></li>
                  <li><Link to="/">Data Management</Link></li>
                  <li><Link to="/">Data Intelligence</Link></li>
                  <li><Link to="/">Web & Brand Design</Link></li>
                </ul>
              </li>
              {/*<li className="dropdown" onClick={() => setPortfolio(Portfolio === true ? false : true)}>
                <Link to="#" className={Portfolio ? "expanded" : ""}>Portfolio<button aria-label="dropdown toggler" className={Portfolio ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                <ul className="sub-menu" style={{ display: Portfolio ? "block" : "none" }}>
                  <li><Link to="/portfolio">Portfolio</Link></li>
                  <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                </ul>
              </li>*/}
              <li className="dropdown" onClick={() => setNews(News === true ? false : true)}>
                <Link to="blog" className={News ? "expanded" : ""}>Blog<button aria-label="dropdown toggler" className={News ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <Link to="mailto:contact@zemtsidata.com">contact@zemtsidata.com</Link>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <Link to="tel:+237653364045">(+237)653 364 045</Link>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <Link to="tel:+237653364045" className="fab fa-whatsapp"></Link>
              <Link to="#" className="fab fa-facebook-square"></Link>
              <Link to="https://www.linkedin.com/company/zemtsidata" className="fab fa-linkedin"></Link>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default Main
